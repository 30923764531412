label {
  display: block;
  margin-top :0px;
}

.signup-form {
  max-width: 350px;
  margin: auto;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.flexbox-container-horiz {
  display: flex;
  flex-direction: row;
  border: 1px solid #ced4da;
  padding: 3px;
}

.flexbox-container-vert {
  display: flex;
  flex-direction: column;
  border: 1px solid #ced4da;
  padding: 3px;
}

.flexbox-container-vert-no-border {
  display: flex;
  flex-direction: column;
  border: 0px;
  padding: 3px;
}

.ul-none {
  list-style-type: none;
  padding-left: 5px;
}

.flexbox-container-horiz-no-border {
  display: flex;
  flex-direction: row;
  border: 0px;
  padding: 0px;
}

.button-borderless {
  appearance: auto;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  margin: 0em;
  font: 400 13.3333px Arial;
  padding: 0px;
  border-width: 0px;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.btn-success-thin {
  padding: 1px;
  padding-left: 8px;
  padding-right: 8px;
  border-color: #8cc638;
  background-color: #8cc638;
}

/* Position and sizing of burger button */
.bm-burger-button {
  display: none;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

text-left * {
  justify-content: left;
  text-align: left;
  padding: "2px";
}

text-center * {
  justify-content: center;
  text-align: center;
  padding: "2px";
}

.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
  padding-left: 2px !important;
  padding-right: 2px !important;
  flex-wrap: wrap !important;
}

.ag-header-cell-text {
  word-break: keep-all !important;
  white-space: normal !important;
}

/* file upload box */
.hiaROs {
  margin: 5px 0px 15px !important;
}

.form-check-input {
  margin-top: 10px;
}

.form-check-label {
  margin-top: 6px;
}

.header-text-center .ag-header-cell-label {
  justify-content: center;
}

.menuClass {
  background-color: #384e6d;
}

.menu-item {
  color: #fff;
  margin-left: 32px;
}

.menu-item.active {
  color: #8cc638;
}

.container {
  width: 95% !important;
  max-width: 1600px;
}

.ag-cell-value {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.language-select-mobile {
  text-align: center;
  margin-top: 32px;
}

.content-container {
  width: 670px;
  margin: auto;
  box-shadow: 0px 5px 7px 3px rgba(0, 0, 0, 0.5);
  border-radius: 64px;
  padding: 32px 16px;
  margin-bottom: 32px;
  background-color: #fff;
}
.content-container.two-column-form {
  width: 768px;
}

.two-column-form {
  padding: 0;
}

.two-column-form .image-container {
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 0 64px 64px 0;
}

.two-column-form .image-container img {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 0;
  height: 100%;
  width: auto;
}

.two-column-form > .left {
  padding: 40px 32px;
}

.licPlateWidth {
  width: 250px;
}

@media screen and (max-width: 600px) {
  .bm-burger-button {
    display: unset;
    position: fixed;
    z-index: 10001;
    width: 36px;
    height: 30px;
    right: 36px;
    top: 16px;
  }

  .bm-burger-bars {
    background-color: white;
  }

  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    height: 64px;
  }

  .navbar .navbar-nav * {
    display: none;
  }

  #blabla {
    margin-top: 64px;
  }

  .language-select {
    display: none;
  }

  .content-container {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .container {
    width: 100% !important;
  }

  .content-container.two-column-form {
    width: auto;
  }

  .two-column-form .image-container {
    height: 400px;
    overflow: hidden;
    position: relative;
    border-radius: 0 0 64px 64px;
  }

  .two-column-form > div {
    width: 100%;
  }
}
