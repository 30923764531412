$base-color: #3f4343;
$blue-color: #8cc638;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", Tahoma, Geneva, Verdana, sans-serif;
  color: var(--fontMainColor);
  background-color: #f5f5f5 !important;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
  color: unset;
  background-color: unset;
  border-color: unset;
}

h1 {
  text-align: center;
  margin-top: 1rem;
  font-size: 2.2rem;
}

.btn {
  letter-spacing: 1px;
  border-radius: 32px;

  &.btn-primary {
    border-color: $blue-color;
    background-color: $blue-color;
  }

  &.btn-secondary {
    border-color: #384e6d;
    background-color: #384e6d;
  }

  &.btn-red {
    border-color: red;
    background-color: red;
  }
}

.steps {
  display: flex;
  justify-content: center;
  letter-spacing: 1px;
  margin-top: 2rem;

  .step {
    margin: 0.5rem 3rem;
    display: flex;
    color: #ccc;

    a {
      color: $base-color;
    }

    div:first-of-type {
      border: 1px solid #ccc;
      padding: 5px;
      border-radius: 50%;
      margin-right: 0.5rem;
      width: 2rem;
      height: 2rem;
      text-align: center;
      line-height: 100%;
      padding-top: 7%;
    }

    :last-child {
      margin-top: 3%;
    }

    &.active {
      div:first-of-type {
        background-color: $blue-color;
        border: 1px solid transparent;
        color: #fff;
      }

      div:last-of-type {
        color: $base-color;
      }
    }
  }
}

.input-form {
  width: 80%;
  margin: 1rem auto;
}

.errorMsg {
  max-width: 350px;
  color: #f21e08;
  padding: 2px 0;
  margin-top: 2px;
  font-size: 14px;
  font-weight: 300;
}

.login-error {
  font-size: 1.1rem;
}

.successMsg {
  color: $blue-color;
}

.loading {
  color: #777;
}

.form-control.input-error {
  border: 2px solid #f21e08;
}

.user-details {
  color: $blue-color;
  padding: 5px 0;
  margin-bottom: 1rem;
}

.navbar {
  .navbar-logo {
    width: 120px;
    min-width: 120px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .nav-a-link {
    color: $blue-color;

    &:hover {
      color: $blue-color;
    }
  }

  .nav-link {
    color: var(--fontMenuColor);

    &:hover {
      color: $blue-color;
    }
  }

  .active {
    color: $blue-color !important;
  }
}

.footer {
  width: 100%;
  height: 230px;
  padding: 16px;
  background: var(--footerBgColor);
  color: var(--fontMenuColor);
  text-align: center;

  .footer-text {
    max-width: 670px;
    margin: 16px auto;
  }

  .footer-links {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 670px;
    margin: auto;
  }

  .copyright {
    margin-top: 16px;
  }

  .nav-link {
    color: var(--fontMenuColor);
    font-size: 0.9rem;

    &:hover {
      color: $blue-color;
    }
  }
}

#blabla {
  // make the footer always appear at the bottom on short screens
  // 56px header height + 230px footer height = 286px
  // Mobile: 56px + 400px = 456px
  min-height: calc(100vh - 286px);
}

.eCOwo-card {
  width: 100%;
  text-align: center;
  padding: 15px;

  .wrapper {
    border: 2px solid;
    border-radius: 6px;
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
  }

  .heading,
  .bottom,
  .content {
    padding: 10px;
  }

  .bottom {
    padding-top: 0;
  }

  .heading {
    .subtitle {
      font-weight: normal;
    }
  }

  &.primary {
    .wrapper {
      border-color: $blue-color;
    }
    .heading {
      background: $blue-color;
      color: #fff;
    }
  }

  &.secondary {
    .wrapper {
      border-color: #384e6d;
    }
    .heading {
      background: #384e6d;
      color: #fff;
    }
  }
}

@media screen and (max-width: 1200px) {
  .input-form {
    width: 90%;
  }
  .col-md-6 {
    width: 90%;
  }
}

@media screen and (max-width: 900px) {
  .input-form {
    width: 90%;
  }
  .steps .step {
    margin: 0.5rem 2rem;
  }
  .col-md-6 {
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .input-form {
    width: 90%;
  }
  h1 {
    font-size: 2rem;
  }

  .steps .step {
    margin: 0.5rem 1rem;
    font-size: 0.9rem;

    div:first-of-type {
      padding-top: 9%;
    }
  }
  .col-md-6 {
    width: 90%;
  }

  .col-sm-12 {
    width: 100%;
  }

  .footer {
    height: 400px;

    .footer-links {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
    }
  }

  #blabla {
    min-height: calc(100vh - 456px);
  }
}

@media screen and (max-width: 800px) {
  .input-form {
    width: 90%;
  }
  .col-md-6 {
    width: 90%;
  }
  .steps {
    flex-direction: column;
    align-items: center;

    .step {
      align-items: center;
    }
  }
  .licPlateWidth {
    width: 150px;
  }
}

.customModal {
  max-width: 1100px;
  width: 100%;
}

.swal2-title {
  color: var(--fontMainColor)!important
}

.swal2-html-container {
  color: var(--fontMainColor)!important
}

.swal2-styled.swal2-confirm {
  background-color:#8cc638!important;
}

.ag-theme-alpine {
  color: var(--fontMainColor)!important
}

.ag-theme-alpine .ag-row {
  color: var(--fontMainColor)!important
}

.ag-theme-alpine .ag-header-row {
  color: var(--fontMainColor)!important
}